import i18n from '@/i18n'
import Store from '@/store'
// import { dateFormat } from 'highcharts'
// import { Time } from 'highcharts'
// import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (reportTitle, data, thisObject) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })
    if (i18n.locale === 'bn') {
      pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    } else {
      pdfMake.vfs = pdfFontsEn.pdfMake.vfs
    }
    // const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
    const pdfContent = [
      { text: reportTitle, style: 'header2', alignment: 'center' }
    ]

    const reportHeader = [
      [
        { text: '', style: 'th', alignment: 'center', rowSpan: 5 },
        { text: i18n.locale === 'bn' ? thisObject.$t('irriPumpInformation.project') + ':      ' + data.project_name_bn + '                                                             ' + data.org_name_bn : thisObject.$t('irriPumpInformation.project') + ':  ' + data.project_name + '                                                                  ' + data.org_name, style: 'logReportFont', alignment: 'left', rowSpan: 5, colSpan: 3 },
        {},
        {},
        { text: thisObject.$t('irriPumpInformation.well_no'), style: 'th', alignment: 'left' },
        { text: data.well_no, style: 'logReportFont', alignment: 'left' }
      ],
      [
        {},
        {},
        {},
        {},
        { text: thisObject.$t('globalTrans.district'), style: 'th', alignment: 'left' },
        { text: i18n.locale === 'bn' ? data.district_name_bn : data.district_name, style: 'logReportFont', alignment: 'left' }
      ],
      [
        {},
        {},
        {},
        {},
        { text: thisObject.$t('irriPumpInformation.upazila'), style: 'th', alignment: 'left' },
        { text: i18n.locale === 'bn' ? data.upazilla_name_bn : data.upazilla_name, style: 'logReportFont', alignment: 'left' }
      ],
      [
        {},
        {},
        {},
        {},
        { text: thisObject.$t('globalTrans.union'), style: 'th', alignment: 'left' },
        { text: i18n.locale === 'bn' ? data.union_name_bn : data.union_name, style: 'logReportFont', alignment: 'left' }
      ],
      [
        {},
        {},
        {},
        {},
        { text: thisObject.$t('irriPumpInformation.mouza'), style: 'thStatic', alignment: 'left' },
        { text: i18n.locale === 'bn' ? thisObject.$n(data.mouza_no) : data.mouza_no, style: 'thStatic', alignment: 'left' }
      ],
      [
        { text: i18n.locale === 'bn' ? thisObject.$t('irriPumpInformation.engineer') + ' : ' + data.engineer_name_bn + '                                               ' + data.org_name_bn : thisObject.$t('irriPumpInformation.engineer') + ' : ' + data.engineer_name + '                                                                ' + data.org_name, style: 'logReportFont', alignment: 'center', rowSpan: 3, colSpan: 2 },
        {},
        { text: i18n.locale === 'bn' ? thisObject.$t('irriPumpInformation.drilling_contractor') + '                                                ' + data.drilling_contractor_name_bn : thisObject.$t('irriPumpInformation.drilling_contractor') + '                         ' + data.drilling_contractor_name, style: 'logReportFont', alignment: 'center', rowSpan: 3, colSpan: 2 },
        {},
        { text: thisObject.$t('irriPumpInformation.jl_no'), style: 'th', alignment: 'left' },
        { text: i18n.locale === 'bn' ? thisObject.$n(data.jl_no) : data.jl_no, style: 'logReportFont', alignment: 'left' }
      ],
      [
        {},
        {},
        {},
        {},
        { text: thisObject.$t('irriPumpInformation.plot_no'), style: 'th', alignment: 'left' },
        { text: i18n.locale === 'bn' ? thisObject.$n(data.plot_no) : data.plot_no, style: 'logReportFont', alignment: 'left' }
      ],
      [
        {},
        {},
        {},
        {},
        { text: thisObject.$t('irriPumpInformation.drilling_logc'), style: 'th', alignment: 'center', colSpan: 2 },
        {}
      ]
    ]

    pdfContent.push({
      table: {
        headerRows: 1,
        widths: ['*', '*', '*', '*', '*', '*'],
        body: reportHeader
      }
    })

    pdfContent.push([
      { text: '', style: 'header3', alignment: 'center', decoration: 'underline' }
    ])
    const drillingMidHead = [
      [
        { text: thisObject.$t('irriPumpInformation.date_started'), style: 'logReportFont', alignment: 'left' },
        { text: ':', style: '', alignment: 'center' },
        { text: dateFormat(data.drilling_start_date) + '  ' + data.drilling_start_time, style: 'logReportFont', alignment: 'left' },
        { text: thisObject.$t('irriPumpInformation.date_completed'), style: 'logReportFont', alignment: 'left' },
        { text: ':', style: '', alignment: 'center' },
        { text: dateFormat(data.drilling_complete_date) + '  ' + data.drilling_complete_time, style: 'logReportFont', alignment: 'left' }
      ],
      [
        { text: thisObject.$t('irriPumpInformation.logged_by'), style: 'logReportFont', alignment: 'left' },
        { text: ':', style: '', alignment: 'left' },
        { text: data.logged_by, style: 'logReportFont', alignment: 'left' },
        { text: thisObject.$t('irriPumpInformation.rod_length'), style: 'logReportFont', alignment: 'left' },
        { text: ':', style: '', alignment: 'left' },
        { text: thisObject.$n(data.rod_length) + ' ' + thisObject.$t('irriPumpInformation.ft'), style: 'logReportFont', alignment: 'left' }
      ]
    ]

    pdfContent.push({
      table: {
        headerRows: 1,
        widths: ['15%', '2%', '30%', '20%', '2%', '22%'],
        body: drillingMidHead
      },
      layout: {
        hLineWidth: function (i, node) {
          return 0
        },
        vLineWidth: function (i, node) {
          return 0
        }
      }
    })

    pdfContent.push([
      { text: '', style: 'header3', alignment: 'center', decoration: 'underline' }
    ])
    const drillingDetails = [
      [
        { text: thisObject.$t('irriPumpInformation.drilling_time_log'), style: 'th', alignment: 'center', colSpan: 3 },
        {},
        {},
        { text: thisObject.$t('irriPumpInformation.graphic_log'), style: 'th', alignment: 'left', rowSpan: 2 },
        { text: thisObject.$t('irriPumpInformation.thickness'), style: 'th', alignment: 'center', colSpan: 2 },
        {},
        { text: thisObject.$t('irriPumpInformation.description_of_formation_re'), style: 'th', alignment: 'left' }
      ],
      [
        { text: thisObject.$t('irriPumpInformation.rod'), style: 'th', alignment: 'left' },
        { text: thisObject.$t('irriPumpInformation.from'), style: 'th', alignment: 'left' },
        { text: thisObject.$t('irriPumpInformation.to'), style: 'th', alignment: 'left' },
        { text: '11', style: 'th', alignment: 'left' },
        {},
        { text: thisObject.$t('irriPumpInformation.from'), style: 'th', alignment: 'left' },
        { text: thisObject.$t('irriPumpInformation.to'), style: 'th', alignment: 'left' }
      ],
      [
        { text: i18n.locale === 'bn' ? data.rod_bn : data.rod, style: 'logReportFont', alignment: 'left' },
        { text: data.rod_from, style: 'logReportFont', alignment: 'left' },
        { text: data.rod_to, style: 'logReportFont', alignment: 'left' },
        { text: data.graphic_log_date, style: 'logReportFont', alignment: 'left' },
        { text: data.thickness_from, style: 'logReportFont', alignment: 'left' },
        { text: data.thickness_to, style: 'logReportFont', alignment: 'left' },
        { text: i18n.locale === 'bn' ? data.description_bn : data.description, style: 'logReportFont', alignment: 'left' }
      ]
    ]
    pdfContent.push({
      table: {
        headerRows: 1,
        widths: ['*', '*', '*', '*', '*', '*', '*'],
        body: drillingDetails
      }
    })

    pdfContent.push([
      { text: '', style: 'header3', alignment: 'center', decoration: 'underline' }
    ])
    pdfContent.push([
      { text: '', style: 'header3', alignment: 'center', decoration: 'underline' }
    ])
    pdfContent.push([
      { text: '', style: 'header3', alignment: 'center', decoration: 'underline' }
    ])

    const drillingFooter = [
      [
        { text: thisObject.$t('irriPumpInformation.sub_assistant_engineer'), style: '', alignment: 'center' },
        { text: thisObject.$t('irriPumpInformation.assistant_engineer'), style: '', alignment: 'center' },
        { text: thisObject.$t('irriPumpInformation.for_the_contractor'), style: '', alignment: 'center' }
      ],
      [
        { text: thisObject.$t('irriPumpInformation.bmda_dri'), style: '', alignment: 'center' },
        { text: thisObject.$t('irriPumpInformation.bmda'), style: '', alignment: 'center' },
        { text: thisObject.$t('irriPumpInformation.date'), style: '', alignment: 'center' }
      ]
    ]

    pdfContent.push({
      table: {
        headerRows: 1,
        widths: ['*', '*', '*'],
        body: drillingFooter
      },
      style: 'tableFooter',
      layout: {
        hLineWidth: function (i, node) {
          return 0
        },
        vLineWidth: function (i, node) {
          return 0
        }
      }
    })

    const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          tableFooter: {
            margin: [0, 60, 0, 0],
            fontSize: (i18n === 'bn') ? 11 : 9,
            bold: true
          },
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3],
            bold: true
          },
          thStatic: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [43, 3, 3, 3],
            bold: true
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [0, 10, 0, 7]
          },
          fertilizerSHeader: {
              fontSize: 10,
              margin: [40, 0, 0, 0]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: i18n.locale === 'bn' ? 14 : 12,
            margin: [0, 10, 0, 10]
          },
          header3: {
            fontSize: i18n.locale === 'bn' ? 13 : 11,
            margin: [0, 10, 0, 5]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          logReportFont: {
            fontSize: 9
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
