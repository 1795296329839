<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row class="details_drilling_log">
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col>
                        <h5 class="text-right">{{ $i18n.locale === 'bn' ? 'ফরম - এ' : 'Form - A' }}</h5>
                      </b-col>
                    </b-row>
                    <b-row class="mb-1">
                      <b-col lg="12" sm="12">
                        <div>
                            <b-table-simple small bordered hover class="mt-3">
                                <b-tr>
                                    <b-th rowspan="5" colspan="2" style="width:18%"><img :src="commonServiceBaseUrl + 'download-attachment?file=uploads/org-profile/' + deepTubwell.logo" alt="Logo"></b-th>
                                    <b-th rowspan="5" colspan="6" class="text-left align-top" style="width:50%">
                                        <p class="font-weight-bold">{{ $t('irriPumpInformation.project') }}: {{ $i18n.locale === 'bn' ? deepTubwell.project_name_bn : deepTubwell.project_name }}</p>
                                        <!-- <h5 class="font-weight-bold text-capitalize">{{ $t('irriPumpInformation.driling_log_h1') }}</h5> -->
                                        <h5 class="font-weight-bold text-capitalize text-center">{{ $i18n.locale === 'bn' ? deepTubwell.org_name_bn : deepTubwell.org_name }}</h5>
                                        <h5 class="font-weight-bold text-capitalize text-center">{{ $i18n.locale === 'bn' ? deepTubwell.division_name_bn : deepTubwell.division_name }}</h5>
                                    </b-th>
                                    <b-th style="width:12%">
                                        <p class="font-weight-bold text-left mb-0">{{ $t('irriPumpInformation.well_no') }}</p>
                                    </b-th>
                                    <td style="width:2%">:</td>
                                    <td class="text-left" style="width:18%">{{ deepTubwell.well_no }}</td>
                                </b-tr>
                                <b-tr>
                                    <b-th>
                                        <p class="font-weight-bold text-left mb-0">{{ $t('globalTrans.district') }}</p>
                                    </b-th>
                                    <td>:</td>
                                    <td class="text-left">{{ $i18n.locale === 'bn' ? deepTubwell.district_name_bn : deepTubwell.district_name }}</td>
                                </b-tr>
                                <b-tr>
                                    <b-th>
                                        <p class="font-weight-bold text-left mb-0">{{ $t('irriPumpInformation.upazila') }}</p>
                                    </b-th>
                                    <td>:</td>
                                    <td class="text-left">{{ $i18n.locale === 'bn' ? deepTubwell.upazilla_name_bn : deepTubwell.upazilla_name }}</td>
                                </b-tr>
                                <b-tr>
                                    <b-th>
                                        <p class="font-weight-bold text-left mb-0">{{ $t('globalTrans.union') }}</p>
                                    </b-th>
                                    <td>:</td>
                                    <td class="text-left">{{ $i18n.locale === 'bn' ? deepTubwell.union_name_bn : deepTubwell.union_name }}</td>
                                </b-tr>
                                <b-tr>
                                    <b-th>
                                        <p class="font-weight-bold text-left mb-0">{{ $t('irriPumpInformation.mouza') }}</p>
                                    </b-th>
                                    <td>:</td>
                                    <td class="text-left">{{ $n(deepTubwell.mouza_no) }}</td>
                                </b-tr>
                                <b-tr>
                                    <b-th rowspan="3" colspan="4">
                                        <p class="font-weight-bold text-center">{{ $t('irriPumpInformation.engineer') }} </p>
                                        <p class="font-weight-bold text-center  mb-0 ">{{ $i18n.locale === 'bn' ? deepTubwell.engineer_name_bn : deepTubwell.engineer_name }} </p>
                                        <p class="font-weight-bold text-center mb-0 mw-50">{{ $i18n.locale === 'bn' ? deepTubwell.org_name_bn : deepTubwell.org_name }}</p>
                                    </b-th>
                                    <b-th rowspan="3" colspan="4">
                                        <p class="font-weight-bold text-center mt-0">{{ $t('irriPumpInformation.drilling_contractor') }} </p>
                                        <p class="font-weight-bold text-center mt-0">{{ $i18n.locale === 'bn' ? deepTubwell.drilling_contractor_name_bn : deepTubwell.drilling_contractor_name }} </p>
                                    </b-th>
                                    <b-th>
                                        <p class="font-weight-bold text-left mb-0">{{ $t('irriPumpInformation.jl_no') }}</p>
                                    </b-th>
                                    <td>:</td>
                                    <td class="text-left">{{ $n(deepTubwell.jl_no) }}</td>
                                </b-tr>
                                <b-tr>
                                    <b-th>
                                        <p class="font-weight-bold text-left mb-0">{{ $t('irriPumpInformation.plot_no') }}</p>
                                    </b-th>
                                    <td>:</td>
                                    <td class="text-left">{{ $n(deepTubwell.plot_no) }}</td>
                                </b-tr>
                                <b-tr>
                                    <b-th colspan="3">
                                        <h5 class="font-weight-bold text-capitalize text-center">{{ $t('irriPumpInformation.drilling_logc') }}</h5>
                                    </b-th>
                                </b-tr>
                                <b-tr>
                                    <b-th colspan="11">
                                        <b-row>
                                            <b-col sm="6" xs="6">
                                                <p class="font-weight-bold text-left mb-0">
                                                    {{ $t('irriPumpInformation.date_started')}}  : {{ deepTubwell.drilling_start_date | dateFormat }}     {{ $t('irriPumpInformation.at') }} : {{ deepTubwell.drilling_start_time }}     {{ $t('irriPumpInformation.hrs') }}
                                                </p>
                                            </b-col>
                                            <b-col sm="6" xs="6">
                                                <p class="font-weight-bold text-left mb-0">
                                                    {{ $t('irriPumpInformation.date_completed')}}  : {{ deepTubwell.drilling_complete_date | dateFormat }}      {{ $t('irriPumpInformation.at') }} : {{ deepTubwell.drilling_complete_time }}      {{ $t('irriPumpInformation.hrs') }}
                                                </p>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col sm="6" xs="6">
                                                <p class="font-weight-bold text-left mb-0">
                                                    {{ $t('irriPumpInformation.logged_by')}}  : {{ deepTubwell.logged_by }}
                                                </p>
                                            </b-col>
                                            <b-col sm="6" xs="6">
                                                <p class="font-weight-bold text-left mb-0">
                                                    {{ $t('irriPumpInformation.rod_length')}}  : {{ deepTubwell.rod_length }} {{ $t('irriPumpInformation.ft') }}
                                                </p>
                                            </b-col>
                                        </b-row>
                                    </b-th>
                                </b-tr>
                                <b-tr>
                                    <b-th colspan="3">
                                        <p class="font-weight-bold text-center mb-0">
                                            {{ $t('irriPumpInformation.drilling_time_log')}}
                                       </p>
                                    </b-th>
                                    <b-th rowspan="2" colspan="2">
                                        <p class="font-weight-bold text-center mb-0">
                                            {{ $t('irriPumpInformation.graphic_log')}}
                                       </p>
                                    </b-th>
                                    <b-th colspan="2">
                                        <p class="font-weight-bold text-center mb-0">
                                            {{ $t('irriPumpInformation.thickness')}}
                                       </p>
                                    </b-th>
                                    <b-th rowspan="2" colspan="4">
                                        <p class="font-weight-bold text-center mb-0">
                                            {{ $t('irriPumpInformation.description_of_formation_re')}}
                                       </p>
                                    </b-th>
                                </b-tr>
                                <b-tr>
                                    <b-th>
                                        <p class="font-weight-bold text-center mb-0">
                                            {{ $t('irriPumpInformation.rod')}}
                                       </p>
                                    </b-th>
                                    <b-th>
                                        <p class="font-weight-bold text-center mb-0">
                                            {{ $t('irriPumpInformation.from')}}
                                       </p>
                                    </b-th>
                                    <b-th>
                                        <p class="font-weight-bold text-center mb-0">
                                            {{ $t('irriPumpInformation.to')}}
                                       </p>
                                    </b-th>
                                    <b-th>
                                        <p class="font-weight-bold text-center mb-0">
                                            {{ $t('irriPumpInformation.from')}}
                                       </p>
                                    </b-th>
                                    <b-th>
                                        <p class="font-weight-bold text-center mb-0">
                                            {{ $t('irriPumpInformation.to')}}
                                       </p>
                                    </b-th>
                                </b-tr>
                                <b-tr style="height:200px">
                                    <b-td style="width:8%">
                                        {{ $i18n.locale === 'bn' ? deepTubwell.rod_bn : deepTubwell.rod }}
                                    </b-td>
                                    <b-td style="width:8%">
                                        {{ $i18n.locale === 'bn' ? deepTubwell.rod_from : deepTubwell.rod_from }}
                                    </b-td>
                                    <b-td style="width:8%">
                                        {{ $i18n.locale === 'bn' ? deepTubwell.rod_to : deepTubwell.rod_to }}
                                    </b-td>
                                    <b-td colspan="2" style="width:16%">
                                        {{ $n(deepTubwell.thickness) }}
                                    </b-td>
                                    <b-td style="width:12%">
                                        {{ deepTubwell.thickness_from }}
                                    </b-td>
                                    <b-td style="width:12%">
                                        {{ deepTubwell.thickness_to }}
                                    </b-td>
                                    <b-td class="text-left" colspan="4" style="width:36%">
                                        {{ $i18n.locale === 'bn' ? deepTubwell.description_bn : deepTubwell.description }}
                                    </b-td>
                                </b-tr>
                            </b-table-simple>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="mb-1 mt-5">
                      <!-- <b-col lg="12" sm="12" style="height:70px">
                        N.B . {{ $t('irriPumpInformation.fixture_design') }}
                      </b-col> -->
                      <b-col lg="4" sm="4" class="text-center">
                        {{ $t('irriPumpInformation.sub_assistant_engineer') }}
                        <div class="col-12"></div>
                        {{ $t('irriPumpInformation.bmda_dri') }} ................................
                      </b-col>
                      <b-col lg="4" sm="4" class="text-center">
                        {{ $t('irriPumpInformation.assistant_engineer') }}
                        <div class="col-12"></div>
                        {{ $t('irriPumpInformation.bmda') }} ...............................
                      </b-col>
                      <b-col lg="4" sm="4" class="text-center">
                        {{ $t('irriPumpInformation.for_the_contractor') }}
                        <div class="col-12"></div>
                        {{ $t('irriPumpInformation.date') }} ...........................
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { commonServiceBaseUrl } from '../../../../../config/api_config'
import ExportPdf from './export_pdf_details'

export default {
  name: 'FormLayout',
  props: ['id', 'item'],
  created () {
    this.deepTubwell = this.item
  },
  data () {
    return {
      commonServiceBaseUrl: commonServiceBaseUrl,
      deepTubwell: '',
      slOffset: 1
    }
  },
  computed: {
  },
  methods: {
    pdfExport () {
    const reportTitle = this.$i18n.locale === 'en' ? 'Pump Report Details' : 'পাম্প প্রতিবেদন বিস্তারিত'
    ExportPdf.exportPdfDetails(reportTitle, this.deepTubwell, this)
    }
  }
}
</script>
<style lang="scss">
  .details_drilling_log {
    font-size: 10px;
    color: #000;
    h5 {
      color: #000 !important;
      font-size: 12px !important;
    }
    .table-bordered td:nth-child(2n+1) {
      font-weight: 700;
    }
    .table-bordered th {
      text-align: center;
      font-weight: 900 !important;
      vertical-align: middle !important;
    }
    .table-bordered tr td {
      text-align: center;
    }
  }
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
